<script>
import DocumentVersionsModuleGuard from '@/mixins/ModulesGuards/Documents/DocumentVersionsModuleGuard'

import * as Files from '@/helpers/files'
import { mapState } from "vuex";

export default {
	name: 'PreviewAbstractBar',
	components: {
		DocumentPages: () => ({
			component: import('@/components/Documents/Preview/PreviewToolBar/DocumentPages')
		}),
		DocumentsNavigation: () => ({
			component: import('@/components/Documents/Preview/PreviewToolBar/DocumentsNavigation')
		}),
		DocumentActions: () => ({
			component: import('@/components/Documents/Preview/PreviewToolBar/DocumentActions')
		})
	},
	mixins: [DocumentVersionsModuleGuard],
	props: {
		closable: {
			type: Boolean,
			default: true,
			required: false
		},
		displayThumbnails: {
			type: Boolean,
			required: true
		},
		index: {
			type: Number,
			required: false,
			default: 0
		},
		items: {
			type: Array,
			required: false,
			default: () => []
		},
		page: {
			type: Number,
			required: false,
			default: 1
		},
		totalDocuments: {
			type: Number,
			required: true
		},
		totalPages: {
			type: Number,
			required: false,
			default: 0
		},
		version: {
			type: Object,
			required: false,
			default: null
		},
		isEditable: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	computed: {
		...mapState({
			currentDocument: state => state.documents.preview.current,
		}),
		documentHasMultiplePages: function () {
			return this.totalPages > 1
		},
		isReadonly: function () {
			return this.version !== null && this.version.id !== this.currentDocument?.latestVersion?.id
		},
		canEdit: function () {
			return this.isEditable || this.model?.type === Files.mimetypes.pdf_a || this.model?.has_signatures
		},
		multipleDocuments: function () {
			return this.totalDocuments > 1
		}
	},
	methods: {
		closeMe: function () {
			this.$emit('close')
		},
		hideSideBar: function () {
			this.eventBus.emit(this.events.preview.HIDE_SIDE_BAR)
		},
		showSideBar: function () {
			this.eventBus.emit(this.events.preview.SHOW_SIDE_BAR)
		}
	},
	render: function () {
		return null
	}
}
</script>
