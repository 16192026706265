<template>
	<v-toolbar class="bottom-bar" color="primary" dark>
		<DocumentPages
				v-if="canDisplayDocumentPages"
				v-model="currentDocument"
				:display-thumbnails="displayThumbnails"
				:is-readonly="isReadonly"
				:is-editable="canEdit"
				:page="page"
				:total-pages="totalPages"
				:version="version"
				:tooltip="{top: true}"
		/>
		<v-divider v-if="canDisplayDocumentPages" vertical />
		<v-layout :align-center="!canDisplayDocumentPages" :justify-center="!canDisplayDocumentPages">
			<DocumentZoomButton
				top
				z-index="300"
				direction="in"
			/>
			<DocumentZoomButton
				top
				z-index="300"
				direction="out"
			/>
			<DocumentZoomButton
					top z-index="300"
					direction="in"
					:fit="true"
			/>
			<DocumentZoomButton
					top z-index="300"
					direction="out"
					:fit="true"
			/>
			<RotateDocumentButton
					v-if="canRotate && totalPages < 2"
					v-model="currentDocument"
					top z-index="300"
					direction="left"
					:flat="false"
			/>
			<RotateDocumentButton
					v-if="canRotate && totalPages < 2"
					v-model="currentDocument"
					top z-index="300"
					direction="right"
					:flat="false"
			/>
		</v-layout>


	</v-toolbar>
</template>
<script>
import PreviewAbstractBar from "@/components/Documents/Preview/PreviewAbstractBar.vue";

export default {
	name: 'MobilePreviewBar',
	components: {
		RotateDocumentButton: () => ({
			component: import('@/components/Documents/Actions/Rotate/RotateDocumentButton')
		}),
		DocumentZoomButton: () => ({
			component: import('@/components/Documents/Preview/PreviewToolBar/Actions/DocumentZoomButton')
		}),
	},
	extends: PreviewAbstractBar,
	computed: {
		canRotate: function () {
			if (!this.value) {
				return false
			}

			return this.value.latestVersion
					&& (this.version === null || this.version.id === this.value.latestVersion.id)
					&& this.isEditable
					&& !this.conditionalDisplay.isMobileApp
		},
		canDisplayDocumentPages () {
			return !this.conditionalDisplay.isMobileApp && this.totalPages > 1 && this.$vuetify.breakpoint.mdAndDown
		},
	},
}
</script>

<style>
.v-toolbar.bottom-bar {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 100;
}
.bottom-bar .v-toolbar__content .v-btn--icon,
.bottom-bar .v-toolbar__extension .v-btn--icon {
	@media (max-width:960px) {
		margin: 4px;
	}
}
</style>
